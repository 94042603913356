import { useTranslation } from 'react-i18next'

const pathMappings = require('../../pathMapping.json')

const useLocalizedPath = () => {
  const { i18n } = useTranslation()

  const getLocalizedPath = (path: string): string => {
    if (pathMappings[path] && pathMappings[path][i18n.language]) {
      return `/${pathMappings[path][i18n.language]}`
    }
    return `/${path}`
  }

  return getLocalizedPath
}

export default useLocalizedPath
