/**
 * 
 * Generic info
 * https://www.facebook.com/business/help/402791146561655
 * 
 * Advanced info
 * https://developers.facebook.com/docs/meta-pixel/reference/
 * https://developers.facebook.com/docs/facebook-pixel/advanced/
 * 
 */

type WindowWithMethods = Window & {
  fbq: any
}

declare const window: WindowWithMethods

export const FB_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID || process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const pageView = () => {
  window.fbq('track', 'PageView')
}

export const addPaymentInfo = () => {
  window.fbq('track', 'AddPaymentInfo')
}

export const addToCart = (articleId: number) => {
  window.fbq('track', 'AddToCart', {
    content_ids: [articleId],
    content_type: 'product'
  })
}

export const search = () => {
  window.fbq('track', 'Search')
}

export const viewProduct = (articleId: number, productName: string) => {
  window.fbq('track', 'ViewContent', {
    content_ids: [articleId],
    content_name: productName,
    content_type: 'product'
  })
}

export const purchase = (value: number, currency: string) => {
  window.fbq('track', 'Purchase', { value, currency })
}

export const initiateCheckout = () => {
  window.fbq('track', 'InitiateCheckout')
}

export const event = (name: string, options = {}) => {
  window.fbq('track', name, options)
}